import React from "react";

const Trash = () => (
  <svg
    width="15"
    height="19"
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4004 0.799316H3.20039V3.12471H1.01426V3.16963H0.400391V4.76963H1.01426V18.3999H14.1857V4.76963H14.8004V3.16963H14.1857V3.12471H11.4004V0.799316ZM3.20039 4.76963V4.76969H11.4004V4.76963H12.5857V16.7999H2.61426V4.76963H3.20039ZM9.80039 3.12471V2.39932H4.80039V3.12471H9.80039ZM4.93008 14.8638V6.65869H6.53008V14.8638H4.93008ZM8.7875 6.65869V14.8638H10.3875V6.65869H8.7875Z"
      fill="#001450"
    />
  </svg>
);

export default Trash;
