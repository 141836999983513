import React from "react";

const WhatsappColored = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_1696_3310)">
      <path
        d="M9 0C4.0275 0 0 4.0275 0 9C0 10.6875 0.4725 12.2625 1.2825 13.6125L0.0825 18L4.56 16.8225C5.8725 17.565 7.38 18 9 18C13.9725 18 18 13.9725 18 9C18 4.0275 13.9725 0 9 0ZM5.9175 4.8C6.06 4.8 6.2175 4.8 6.345 4.8C6.5025 4.8 6.6825 4.815 6.8475 5.1825C7.0425 5.625 7.4775 6.7275 7.5375 6.84C7.5975 6.9525 7.635 7.08 7.5525 7.23C7.4775 7.38 7.44 7.47 7.335 7.605C7.2225 7.7325 7.1025 7.8975 6.9975 7.995C6.885 8.1075 6.7725 8.2275 6.9 8.4525C7.0275 8.6775 7.4775 9.405 8.1375 9.9975C8.9925 10.755 9.705 10.995 9.93 11.1C10.155 11.2125 10.2825 11.1975 10.41 11.0475C10.545 10.905 10.965 10.4025 11.115 10.1775C11.2575 9.9525 11.4075 9.99 11.61 10.065C11.8125 10.14 12.915 10.68 13.1325 10.7925C13.35 10.905 13.5 10.9575 13.56 11.0475C13.62 11.1375 13.62 11.5875 13.4325 12.105C13.245 12.6225 12.3375 13.125 11.9325 13.1625C11.52 13.2 11.1375 13.35 9.255 12.6075C6.99 11.715 5.5575 9.39 5.445 9.24C5.3325 9.0975 4.5375 8.0325 4.5375 6.9375C4.5375 5.8425 5.115 5.3025 5.3175 5.0775C5.52 4.8525 5.76 4.8 5.91 4.8H5.9175Z"
        fill="#37D16A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1696_3310">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WhatsappColored;
