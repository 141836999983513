import React from "react";

const Truck = () => (
  <svg
    width="58"
    height="39"
    viewBox="0 0 58 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.6503 24H1.03027C0.765057 24 0.510703 23.8946 0.323167 23.7071C0.13563 23.5196 0.0302734 23.2652 0.0302734 23V3C0.0302734 2.20435 0.346344 1.44129 0.908953 0.87868C1.47156 0.316071 2.23462 0 3.03027 0H34.6603C35.4559 0 36.219 0.316071 36.7816 0.87868C37.3442 1.44129 37.6603 2.20435 37.6603 3V23C37.6603 23.1322 37.6341 23.263 37.5832 23.385C37.5323 23.507 37.4578 23.6177 37.3639 23.7107C37.2699 23.8037 37.1585 23.8771 37.036 23.9268C36.9135 23.9764 36.7824 24.0013 36.6503 24ZM2.03027 22H35.6503V3C35.6503 2.73478 35.5449 2.48043 35.3574 2.29289C35.1698 2.10536 34.9155 2 34.6503 2H3.03027C2.76506 2 2.5107 2.10536 2.32317 2.29289C2.13563 2.48043 2.03027 2.73478 2.03027 3V22Z"
      fill="currentColor"
    />
    <path
      d="M56.6504 21.0002H42.1704C41.8386 21.0002 41.5101 20.9347 41.2037 20.8074C40.8973 20.6802 40.6191 20.4936 40.385 20.2586C40.1508 20.0235 39.9654 19.7445 39.8394 19.4376C39.7133 19.1307 39.6491 18.802 39.6504 18.4702V11.4702C39.6504 10.8018 39.9159 10.1609 40.3885 9.68829C40.8611 9.21569 41.5021 8.9502 42.1704 8.9502H49.6504C49.8091 8.95093 49.9653 8.9894 50.1061 9.06243C50.2469 9.13546 50.3684 9.24095 50.4604 9.3702L57.4604 19.3702C57.5667 19.5194 57.6299 19.695 57.6432 19.8778C57.6564 20.0605 57.6193 20.2434 57.5357 20.4064C57.4521 20.5694 57.3253 20.7064 57.1692 20.8023C57.0131 20.8982 56.8336 20.9494 56.6504 20.9502V21.0002ZM42.1704 11.0002C42.1021 11.0002 42.0345 11.0136 41.9714 11.0398C41.9083 11.0659 41.851 11.1042 41.8027 11.1525C41.7544 11.2008 41.7161 11.2581 41.69 11.3212C41.6639 11.3843 41.6504 11.4519 41.6504 11.5202V18.5202C41.6491 18.5893 41.6615 18.658 41.6871 18.7223C41.7126 18.7865 41.7507 18.845 41.7991 18.8944C41.8476 18.9437 41.9053 18.9829 41.9691 19.0097C42.0328 19.0364 42.1013 19.0502 42.1704 19.0502H54.7204L49.1204 11.0502L42.1704 11.0002Z"
      fill="currentColor"
    />
    <path
      d="M36.6503 34H17.0303C16.8907 33.9997 16.7528 33.9701 16.6254 33.9133C16.4979 33.8565 16.3838 33.7736 16.2903 33.67C16.1968 33.5682 16.1254 33.4481 16.0806 33.3173C16.0359 33.1864 16.0187 33.0478 16.0303 32.91V32.72C16.0303 32.72 16.0303 32.63 16.0303 32.59C16.0303 31.6617 15.6615 30.7715 15.0051 30.1151C14.3488 29.4588 13.4585 29.09 12.5303 29.09C11.602 29.09 10.7118 29.4588 10.0554 30.1151C9.39902 30.7715 9.03027 31.6617 9.03027 32.59C9.03027 32.59 9.03027 32.67 9.03027 32.71V32.91C9.04281 33.0487 9.02624 33.1885 8.98163 33.3204C8.93701 33.4523 8.86534 33.5734 8.77121 33.676C8.67707 33.7787 8.56254 33.8605 8.43495 33.9163C8.30736 33.972 8.16952 34.0006 8.03027 34H1.03027C0.765057 34 0.510703 33.8946 0.323167 33.7071C0.13563 33.5196 0.0302734 33.2652 0.0302734 33V23C0.0302734 22.7348 0.13563 22.4804 0.323167 22.2929C0.510703 22.1054 0.765057 22 1.03027 22H36.6503C36.9155 22 37.1698 22.1054 37.3574 22.2929C37.5449 22.4804 37.6503 22.7348 37.6503 23V33C37.6503 33.2652 37.5449 33.5196 37.3574 33.7071C37.1698 33.8946 36.9155 34 36.6503 34ZM18.0303 32H35.6503V24H2.03027V32H7.03027C7.17206 30.648 7.80949 29.3965 8.81959 28.4868C9.8297 27.5771 11.1409 27.0737 12.5003 27.0737C13.8596 27.0737 15.1708 27.5771 16.181 28.4868C17.1911 29.3965 17.8285 30.648 17.9703 32H18.0303Z"
      fill="currentColor"
    />
    <path
      d="M12.5303 38.0898C11.4425 38.0898 10.3791 37.7673 9.47464 37.1629C8.57017 36.5586 7.86522 35.6996 7.44894 34.6946C7.03266 33.6896 6.92374 32.5837 7.13596 31.5168C7.34818 30.45 7.872 29.4699 8.64119 28.7008C9.41038 27.9316 10.3904 27.4077 11.4573 27.1955C12.5242 26.9833 13.63 27.0922 14.635 27.5085C15.64 27.9248 16.499 28.6297 17.1034 29.5342C17.7077 30.4387 18.0303 31.502 18.0303 32.5898C18.0276 34.0477 17.4473 35.4451 16.4164 36.476C15.3856 37.5069 13.9882 38.0872 12.5303 38.0898ZM12.5303 29.0898C11.838 29.0898 11.1614 29.2951 10.5858 29.6797C10.0102 30.0643 9.5616 30.6109 9.2967 31.2505C9.03179 31.89 8.96248 32.5937 9.09753 33.2727C9.23257 33.9516 9.56592 34.5752 10.0554 35.0647C10.5449 35.5542 11.1685 35.8875 11.8475 36.0226C12.5264 36.1576 13.2301 36.0883 13.8697 35.8234C14.5092 35.5585 15.0558 35.1099 15.4404 34.5343C15.825 33.9588 16.0303 33.2821 16.0303 32.5898C16.0303 31.6616 15.6615 30.7713 15.0051 30.115C14.3488 29.4586 13.4585 29.0898 12.5303 29.0898Z"
      fill="currentColor"
    />
    <path
      d="M56.6504 34H52.0304C51.8908 33.9997 51.7529 33.9702 51.6255 33.9133C51.498 33.8565 51.3839 33.7736 51.2904 33.67C51.1969 33.5682 51.1255 33.4481 51.0807 33.3173C51.036 33.1865 51.0188 33.0478 51.0304 32.91V32.72C51.0304 32.72 51.0304 32.63 51.0304 32.59C51.0304 31.6618 50.6616 30.7715 50.0053 30.1151C49.3489 29.4588 48.4586 29.09 47.5304 29.09C46.6021 29.09 45.7119 29.4588 45.0555 30.1151C44.3991 30.7715 44.0304 31.6618 44.0304 32.59C44.0304 32.59 44.0304 32.67 44.0304 32.71V32.91C44.0429 33.0487 44.0264 33.1885 43.9817 33.3204C43.9371 33.4523 43.8655 33.5734 43.7713 33.6761C43.6772 33.7787 43.5627 33.8605 43.4351 33.9163C43.3075 33.9721 43.1696 34.0006 43.0304 34H36.6504C36.3852 34 36.1308 33.8947 35.9433 33.7071C35.7557 33.5196 35.6504 33.2652 35.6504 33V6.00002C35.6504 5.7348 35.7557 5.48044 35.9433 5.29291C36.1308 5.10537 36.3852 5.00002 36.6504 5.00002H45.1604C46.0228 4.99793 46.8721 5.21079 47.6316 5.61934C48.3911 6.0279 49.0368 6.61927 49.5104 7.34002L57.5104 19.45C57.6197 19.6127 57.6789 19.804 57.6804 20V33C57.6805 33.1339 57.6536 33.2664 57.6015 33.3898C57.5494 33.5131 57.473 33.6247 57.377 33.7179C57.2809 33.8112 57.1671 33.8842 57.0423 33.9327C56.9175 33.9811 56.7842 34.004 56.6504 34ZM53.0004 32H55.6504V20.3L47.8404 8.44002C47.547 7.9977 47.1488 7.63477 46.6812 7.38354C46.2136 7.13231 45.6912 7.00056 45.1604 7.00002H37.6504V32H42.0304C42.1722 30.6481 42.8096 29.3965 43.8197 28.4868C44.8298 27.5771 46.141 27.0737 47.5004 27.0737C48.8598 27.0737 50.171 27.5771 51.1811 28.4868C52.1912 29.3965 52.8286 30.6481 52.9704 32H53.0004Z"
      fill="currentColor"
    />
    <path
      d="M47.5303 38.0898C46.4425 38.0898 45.3791 37.7673 44.4746 37.1629C43.5702 36.5586 42.8652 35.6996 42.4489 34.6946C42.0327 33.6896 41.9237 32.5837 42.136 31.5168C42.3482 30.45 42.872 29.4699 43.6412 28.7008C44.4104 27.9316 45.3904 27.4077 46.4573 27.1955C47.5242 26.9833 48.63 27.0922 49.635 27.5085C50.64 27.9248 51.499 28.6297 52.1034 29.5342C52.7077 30.4387 53.0303 31.502 53.0303 32.5898C53.0276 34.0477 52.4473 35.4451 51.4164 36.476C50.3856 37.5069 48.9882 38.0872 47.5303 38.0898ZM47.5303 29.0898C46.838 29.0898 46.1614 29.2951 45.5858 29.6797C45.0102 30.0643 44.5616 30.6109 44.2967 31.2505C44.0318 31.89 43.9625 32.5937 44.0975 33.2727C44.2326 33.9516 44.5659 34.5752 45.0554 35.0647C45.5449 35.5542 46.1685 35.8875 46.8475 36.0226C47.5264 36.1576 48.2301 36.0883 48.8697 35.8234C49.5092 35.5585 50.0558 35.1099 50.4404 34.5343C50.825 33.9588 51.0303 33.2821 51.0303 32.5898C51.0303 31.6616 50.6615 30.7713 50.0051 30.115C49.3488 29.4586 48.4585 29.0898 47.5303 29.0898Z"
      fill="currentColor"
    />
  </svg>
);

export default Truck;
