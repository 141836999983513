import React from "react";

const Unfold = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.60827 2.05806C9.85235 1.81398 10.2481 1.81398 10.4922 2.05806L14.6588 6.22472C14.9029 6.4688 14.9029 6.86453 14.6588 7.10861C14.4147 7.35269 14.019 7.35269 13.7749 7.10861L10.0502 3.38388L6.32549 7.10861C6.08141 7.35269 5.68568 7.35269 5.4416 7.10861C5.19753 6.86453 5.19753 6.4688 5.4416 6.22472L9.60827 2.05806Z"
          fill="#001450"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.60827 17.9419C9.85235 18.186 10.2481 18.186 10.4922 17.9419L14.6588 13.7753C14.9029 13.5312 14.9029 13.1355 14.6588 12.8914C14.4147 12.6473 14.019 12.6473 13.7749 12.8914L10.0502 16.6161L6.32549 12.8914C6.08141 12.6473 5.68568 12.6473 5.4416 12.8914C5.19753 13.1355 5.19753 13.5312 5.4416 13.7753L9.60827 17.9419Z"
          fill="#001450"/>
  </svg>

);

export default Unfold;
