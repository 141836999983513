import React from "react";

const WorkshopCar = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.03829 20.8893H5.98436C5.63995 20.8893 5.30946 20.7533 5.06477 20.511C4.82008 20.2686 4.68098 19.9394 4.67773 19.595V18.1713C4.67773 18.0487 4.72644 17.9311 4.81312 17.8444C4.89981 17.7577 5.01739 17.709 5.13998 17.709H7.85185C7.91278 17.7082 7.97326 17.7196 8.02972 17.7425C8.08618 17.7655 8.13746 17.7995 8.18055 17.8426C8.22364 17.8857 8.25766 17.9369 8.2806 17.9934C8.30354 18.0499 8.31493 18.1103 8.3141 18.1713V19.595C8.31099 19.9341 8.17615 20.2588 7.93807 20.5003C7.7 20.7418 7.37734 20.8813 7.03829 20.8893ZM5.63305 18.6335V19.595C5.63305 19.6964 5.67331 19.7935 5.74497 19.8652C5.81664 19.9369 5.91383 19.9771 6.01518 19.9771H7.03829C7.08847 19.9771 7.13816 19.9672 7.18452 19.948C7.23088 19.9288 7.27301 19.9007 7.30849 19.8652C7.34398 19.8297 7.37212 19.7876 7.39133 19.7412C7.41053 19.6949 7.42042 19.6452 7.42042 19.595V18.6335H5.63305Z"
      fill="currentColor"
    />
    <path
      d="M18.0091 20.8893H16.9922C16.82 20.8917 16.6492 20.8602 16.4893 20.7964C16.3294 20.7326 16.1837 20.6378 16.0606 20.5175C15.9375 20.3973 15.8393 20.2539 15.7718 20.0955C15.7042 19.9372 15.6686 19.7671 15.667 19.595V18.1713C15.6662 18.1103 15.6776 18.0499 15.7005 17.9934C15.7235 17.9369 15.7575 17.8857 15.8006 17.8426C15.8437 17.7995 15.895 17.7655 15.9514 17.7425C16.0079 17.7196 16.0684 17.7082 16.1293 17.709H18.835C18.8959 17.7082 18.9564 17.7196 19.0129 17.7425C19.0693 17.7655 19.1206 17.7995 19.1637 17.8426C19.2068 17.8857 19.2408 17.9369 19.2637 17.9934C19.2867 18.0499 19.2981 18.1103 19.2972 18.1713V19.595C19.294 19.9362 19.1575 20.2627 18.9168 20.5045C18.6761 20.7464 18.3503 20.8845 18.0091 20.8893ZM16.61 18.6335V19.595C16.61 19.6964 16.6503 19.7935 16.7219 19.8652C16.7936 19.9369 16.8908 19.9771 16.9922 19.9771H18.0091C18.1104 19.9771 18.2076 19.9369 18.2793 19.8652C18.351 19.7935 18.3912 19.6964 18.3912 19.595V18.6335H16.61Z"
      fill="currentColor"
    />
    <path
      d="M14.3355 16.0222H9.65756C9.53497 16.0222 9.41739 15.9735 9.3307 15.8868C9.24401 15.8001 9.19531 15.6825 9.19531 15.5599C9.19531 15.4373 9.24401 15.3197 9.3307 15.233C9.41739 15.1464 9.53497 15.0977 9.65756 15.0977H14.3355C14.4581 15.0977 14.5757 15.1464 14.6624 15.233C14.7491 15.3197 14.7978 15.4373 14.7978 15.5599C14.7978 15.6825 14.7491 15.8001 14.6624 15.8868C14.5757 15.9735 14.4581 16.0222 14.3355 16.0222Z"
      fill="currentColor"
    />
    <path
      d="M18.8535 13.0854H18.7857C16.6599 12.7895 14.5161 12.6413 12.3697 12.6416C9.97 12.6586 7.573 12.8068 5.18944 13.0854C5.06699 13.0993 4.94397 13.0644 4.84706 12.9883C4.75015 12.9121 4.68715 12.8009 4.67172 12.6786C4.66398 12.6186 4.66824 12.5577 4.68425 12.4994C4.70026 12.4411 4.7277 12.3866 4.76498 12.339C4.80225 12.2914 4.84863 12.2516 4.90139 12.2221C4.95416 12.1926 5.01227 12.1739 5.07234 12.167C7.49066 11.8834 9.92258 11.7311 12.3574 11.7109C14.5561 11.7202 16.7515 11.8829 18.9275 12.1978C19.0419 12.2167 19.1451 12.2779 19.2165 12.3692C19.2879 12.4606 19.3223 12.5755 19.313 12.691C19.3037 12.8066 19.2513 12.9145 19.1662 12.9932C19.0811 13.072 18.9695 13.1159 18.8535 13.1162V13.0854Z"
      fill="currentColor"
    />
    <path
      d="M6.95255 16.7365C6.68167 16.7377 6.41653 16.6585 6.19071 16.5089C5.96489 16.3593 5.78856 16.146 5.68406 15.8961C5.57955 15.6462 5.55157 15.3709 5.60367 15.1051C5.65577 14.8393 5.78559 14.5949 5.9767 14.4029C6.1678 14.211 6.41159 14.08 6.67718 14.0267C6.94276 13.9734 7.21818 14.0002 7.46856 14.1036C7.71894 14.2069 7.93299 14.3823 8.08361 14.6074C8.23424 14.8326 8.31464 15.0974 8.31464 15.3682C8.31465 15.7301 8.17134 16.0772 7.91607 16.3336C7.6608 16.59 7.31436 16.7349 6.95255 16.7365ZM6.95255 14.9245C6.83486 14.9245 6.72198 14.9712 6.63876 15.0545C6.55554 15.1377 6.50879 15.2506 6.50879 15.3682C6.50879 15.4859 6.55554 15.5988 6.63876 15.682C6.72198 15.7653 6.83486 15.812 6.95255 15.812C7.07024 15.812 7.18311 15.7653 7.26633 15.682C7.34955 15.5988 7.39631 15.4859 7.39631 15.3682C7.39631 15.2506 7.34955 15.1377 7.26633 15.0545C7.18311 14.9712 7.07024 14.9245 6.95255 14.9245Z"
      fill="currentColor"
    />
    <path
      d="M17.0479 16.7365C16.7773 16.7365 16.5128 16.6563 16.2878 16.5059C16.0628 16.3556 15.8874 16.1419 15.7838 15.8919C15.6803 15.6419 15.6532 15.3667 15.706 15.1013C15.7588 14.8359 15.8891 14.5921 16.0804 14.4008C16.2718 14.2094 16.5156 14.0791 16.781 14.0263C17.0464 13.9735 17.3215 14.0006 17.5716 14.1042C17.8216 14.2077 18.0353 14.3831 18.1856 14.6081C18.336 14.8331 18.4162 15.0976 18.4162 15.3683C18.4162 15.7311 18.272 16.0792 18.0155 16.3358C17.7589 16.5924 17.4108 16.7365 17.0479 16.7365ZM17.0479 14.9245C16.9602 14.9245 16.8744 14.9505 16.8014 14.9993C16.7284 15.048 16.6716 15.1174 16.638 15.1984C16.6044 15.2795 16.5956 15.3688 16.6127 15.4548C16.6298 15.5409 16.6721 15.62 16.7342 15.682C16.7962 15.7441 16.8753 15.7864 16.9614 15.8035C17.0475 15.8206 17.1367 15.8118 17.2178 15.7782C17.2989 15.7447 17.3682 15.6878 17.4169 15.6148C17.4657 15.5418 17.4917 15.456 17.4917 15.3683C17.4854 15.2549 17.4359 15.1483 17.3533 15.0703C17.2708 14.9923 17.1615 14.949 17.0479 14.9492V14.9245Z"
      fill="currentColor"
    />
    <path
      d="M5.2575 12.8586C5.19642 12.8609 5.13554 12.8504 5.07876 12.8278L4.01867 12.3902C3.89771 12.3598 3.78409 12.3054 3.68456 12.2302C3.58504 12.155 3.50164 12.0606 3.43932 11.9526C3.36874 11.8387 3.32342 11.711 3.30641 11.5781C3.2894 11.4452 3.30109 11.3102 3.34071 11.1822L3.43316 10.9048C3.51994 10.647 3.68575 10.4231 3.90702 10.2649C4.1283 10.1067 4.3938 10.0222 4.66582 10.0235H6.20665C6.2851 10.0234 6.36221 10.0438 6.43032 10.0827C6.49843 10.1217 6.55518 10.1777 6.59494 10.2453C6.63271 10.3143 6.6525 10.3917 6.6525 10.4703C6.6525 10.5489 6.63271 10.6263 6.59494 10.6953L5.63963 12.6059C5.60338 12.6776 5.54908 12.7386 5.48207 12.7829C5.41506 12.8272 5.33766 12.8533 5.2575 12.8586ZM4.67198 10.9664C4.59672 10.9666 4.52344 10.9905 4.46257 11.0348C4.4017 11.0791 4.35636 11.1414 4.333 11.213L4.23439 11.4965C4.26879 11.5071 4.30187 11.5216 4.333 11.5396L5.03562 11.8293L5.46089 10.9664H4.67198Z"
      fill="currentColor"
    />
    <path
      d="M18.7489 12.8587C18.6632 12.8595 18.5791 12.8363 18.5061 12.7916C18.4331 12.7469 18.3741 12.6826 18.3359 12.606L17.3806 10.6953C17.3411 10.6269 17.3203 10.5493 17.3203 10.4704C17.3203 10.3914 17.3411 10.3138 17.3806 10.2454C17.4222 10.1781 17.4803 10.1225 17.5492 10.0837C17.6182 10.0449 17.6959 10.0242 17.775 10.0235H19.3159C19.5883 10.0204 19.8547 10.1041 20.0764 10.2626C20.298 10.4211 20.4634 10.646 20.5485 10.9049L20.6472 11.1822C20.6915 11.3062 20.7094 11.4381 20.6997 11.5695C20.69 11.7008 20.6529 11.8287 20.5908 11.9448C20.5287 12.0609 20.4429 12.1628 20.339 12.2437C20.2351 12.3247 20.1155 12.383 19.9877 12.4149L18.9214 12.8155C18.8673 12.8411 18.8086 12.8557 18.7489 12.8587ZM18.5331 10.9665L18.9646 11.8294L19.6672 11.5397H19.7473L19.6672 11.2377C19.6438 11.1662 19.5985 11.1038 19.5376 11.0595C19.4768 11.0153 19.4035 10.9913 19.3282 10.9912L18.5331 10.9665Z"
      fill="currentColor"
    />
    <path
      d="M23.5378 4.9245H0.46225C0.339653 4.9245 0.222079 4.8758 0.13539 4.78911C0.0487014 4.70242 0 4.58485 0 4.46225C0 4.33965 0.0487014 4.22208 0.13539 4.13539C0.222079 4.0487 0.339653 4 0.46225 4H23.5378C23.6603 4 23.7779 4.0487 23.8646 4.13539C23.9513 4.22208 24 4.33965 24 4.46225C24 4.58485 23.9513 4.70242 23.8646 4.78911C23.7779 4.8758 23.6603 4.9245 23.5378 4.9245Z"
      fill="currentColor"
    />
    <path
      d="M19.6918 24H4.30817C4.22644 24 4.14805 23.9473 4.09026 23.8536C4.03247 23.7598 4 23.6326 4 23.5C4 23.3674 4.03247 23.2402 4.09026 23.1464C4.14805 23.0527 4.22644 23 4.30817 23H19.6918C19.7736 23 19.8519 23.0527 19.9097 23.1464C19.9675 23.2402 20 23.3674 20 23.5C20 23.6326 19.9675 23.7598 19.9097 23.8536C19.8519 23.9473 19.7736 24 19.6918 24Z"
      fill="currentColor"
    />
    <path
      d="M5.84279 18.6332C5.29463 18.6316 4.76948 18.4127 4.38245 18.0245C3.99541 17.6363 3.77808 17.1105 3.77808 16.5623V14.097C3.77756 13.8589 3.82413 13.6231 3.9151 13.4031C4.00606 13.1831 4.13963 12.9832 4.30813 12.815L4.76421 12.3651L6.25574 9.36971C6.51206 8.85241 6.90853 8.41752 7.39998 8.11459C7.89143 7.81165 8.45809 7.65286 9.0354 7.6563H14.9399C15.5124 7.68508 16.0685 7.85701 16.5573 8.15635C17.0462 8.45569 17.4521 8.87287 17.738 9.36971L19.2357 12.3651L19.6856 12.815C19.8557 12.9836 19.9907 13.1843 20.0827 13.4054C20.1747 13.6265 20.222 13.8637 20.2218 14.1031V16.5685C20.2206 16.8415 20.1655 17.1116 20.0597 17.3633C19.9539 17.615 19.7996 17.8434 19.6055 18.0353C19.4151 18.2298 19.1878 18.3844 18.9371 18.4903C18.6863 18.5961 18.417 18.6509 18.1448 18.6517H5.81814L5.84279 18.6332ZM9.0354 8.56848C8.63026 8.56766 8.2329 8.67966 7.88783 8.89193C7.54275 9.1042 7.26359 9.40835 7.08162 9.77033L5.55312 12.852C5.53212 12.8962 5.50283 12.9359 5.46683 12.9691L4.96144 13.4745C4.79974 13.6394 4.70715 13.8599 4.70258 14.0908V16.5561C4.70257 16.8591 4.82251 17.1498 5.03616 17.3646C5.24982 17.5794 5.53983 17.7009 5.84279 17.7025C5.84279 17.7025 14.4715 17.7025 18.1695 17.7025C18.3199 17.7034 18.4691 17.6742 18.6082 17.6168C18.7473 17.5594 18.8736 17.4749 18.9797 17.3682C19.0859 17.2615 19.1697 17.1348 19.2263 16.9954C19.283 16.8559 19.3113 16.7066 19.3097 16.5561V14.0908C19.3054 13.8586 19.2102 13.6373 19.0446 13.4745L18.5393 12.9691C18.5007 12.9216 18.4677 12.8699 18.4406 12.815L16.9121 9.73335C16.7065 9.3872 16.4192 9.09668 16.0754 8.88718C15.7316 8.67767 15.3418 8.55555 14.9399 8.5315L9.0354 8.56848Z"
      fill="currentColor"
    />
    <path
      d="M23.5378 24C23.4768 24.0009 23.4163 23.9884 23.3599 23.9632C23.3034 23.9381 23.2521 23.9008 23.209 23.8535C23.1659 23.8063 23.1319 23.75 23.109 23.6881C23.0861 23.6262 23.0747 23.5599 23.0755 23.4931V1.58828C23.0755 1.43592 23.0203 1.2898 22.9221 1.18206C22.8238 1.07432 22.6906 1.0138 22.5516 1.0138H1.44838C1.30944 1.0138 1.17619 1.07432 1.07794 1.18206C0.979694 1.2898 0.924499 1.43592 0.924499 1.58828V23.4931C0.924499 23.6275 0.875798 23.7564 0.789109 23.8515C0.702421 23.9465 0.584846 24 0.46225 24C0.339653 24 0.222079 23.9465 0.13539 23.8515C0.0487014 23.7564 0 23.6275 0 23.4931V1.58828C0 1.16704 0.152597 0.763058 0.424221 0.465197C0.695846 0.167336 1.06425 0 1.44838 0H22.5516C22.9352 0.00177884 23.3027 0.169686 23.574 0.467162C23.8453 0.764638 23.9984 1.16759 24 1.58828V23.4931C24 23.6275 23.9513 23.7564 23.8646 23.8515C23.7779 23.9465 23.6603 24 23.5378 24Z"
      fill="currentColor"
    />
  </svg>
);

export default WorkshopCar;
