import React from "react";

const Wheel = () => (
  <svg 
    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
    x="0px" y="0px"
    viewBox="0 0 120 120"
  >
  <path 
    d="M60,22.49c20.72,0,37.51,16.79,37.51,37.51c0,20.72-16.79,37.51-37.51,37.51S22.49,80.72,22.49,60
    C22.49,39.28,39.28,22.49,60,22.49z"
    fill={"none"}
    stroke={"currentColor"}
    strokeWidth={4.0714}
    strokeLinecap={"round"}
    strokeLinejoin={"round"}
    strokeMiterlimit={10}
  />  
  <g>
    <path
      d="M71.65,88.29c0,0-2.68-9.24-6.7-13.89c-0.08-0.09-0.16-0.18-0.24-0.27c-1.43-1.57-2.38-2.3-4.71-2.3
      s-3.28,0.73-4.71,2.3c-0.08,0.09-0.16,0.18-0.24,0.27c-4.02,4.65-6.7,13.89-6.7,13.89S50.6,90.85,60,90.85S71.65,88.29,71.65,88.29
      z"
      fill={"none"}
      stroke={"currentColor"}
      strokeWidth={4}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
      strokeMiterlimit={10}
    />
    <path 
      d="M90.47,57.69c0,0-9.62-0.3-15.28,2.08c-0.11,0.05-0.22,0.09-0.33,0.15c-1.93,0.87-2.92,1.55-3.64,3.77
      c-0.72,2.22-0.32,3.35,0.73,5.19c0.06,0.11,0.12,0.21,0.19,0.32c3.18,5.26,11.14,10.66,11.14,10.66s3.13-1.35,6.03-10.29
      S90.47,57.69,90.47,57.69z"
      fill={"none"}
      stroke={"currentColor"}
      strokeWidth={4}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
      strokeMiterlimit={10}
    />
    <path 
      d="M67.19,30.32c0,0-3.26,9.05-2.75,15.17c0.01,0.12,0.02,0.24,0.04,0.36c0.23,2.11,0.57,3.26,2.46,4.63
      c1.89,1.37,3.09,1.33,5.16,0.91c0.12-0.02,0.24-0.05,0.36-0.08c5.98-1.4,13.58-7.3,13.58-7.3s-0.31-3.39-7.92-8.92
      S67.19,30.32,67.19,30.32z"
      fill={"none"}
      stroke={"currentColor"}
      strokeWidth={4}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
      strokeMiterlimit={10}
    />
    <path 
      d="M29.53,57.69c0,0,9.62-0.3,15.28,2.08c0.11,0.05,0.22,0.09,0.33,0.15c1.93,0.87,2.92,1.55,3.64,3.77
      c0.72,2.22,0.32,3.35-0.73,5.19c-0.06,0.11-0.12,0.21-0.19,0.32c-3.18,5.26-11.14,10.66-11.14,10.66s-3.13-1.35-6.03-10.29
      S29.53,57.69,29.53,57.69z"
      fill={"none"}
      stroke={"currentColor"}
      strokeWidth={4}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
      strokeMiterlimit={10}
    />
    <path 
      d="M52.81,30.32c0,0,3.26,9.05,2.75,15.17c-0.01,0.12-0.02,0.24-0.04,0.36c-0.23,2.11-0.57,3.26-2.46,4.63
      c-1.89,1.37-3.09,1.33-5.16,0.91c-0.12-0.02-0.24-0.05-0.36-0.08c-5.98-1.4-13.58-7.3-13.58-7.3s0.31-3.39,7.92-8.92
      S52.81,30.32,52.81,30.32z"
      fill={"none"}
      stroke={"currentColor"}
      strokeWidth={4}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
      strokeMiterlimit={10}
    />
  </g>
  <path 
    fill={"none"}
    stroke={"currentColor"}
    strokeWidth={4}
    strokeLinecap={"round"}
    strokeLinejoin={"round"}
    strokeMiterlimit={10}
    d="M8.33,75.62l2.84,1.14l-1.55,2.64c0.59,1.54,1.26,3.04,1.98,4.51l2.99,0.65l-1.09,2.85
	c0.84,1.42,1.74,2.8,2.7,4.13l3.05,0.15l-0.6,2.99c1.06,1.26,2.18,2.48,3.35,3.64l3.02-0.35l-0.1,3.04c1.25,1.07,2.55,2.09,3.9,3.04
	l2.93-0.85l0.4,3.03c1.4,0.85,2.85,1.63,4.33,2.35l2.76-1.32l0.9,2.93c1.52,0.6,3.07,1.13,4.66,1.6l2.51-1.76l1.37,2.74
	c1.6,0.34,3.22,0.61,4.87,0.81l2.17-2.14l1.79,2.46c0.82,0.04,1.64,0.06,2.47,0.06c0.83,0,1.65-0.02,2.47-0.06l1.79-2.46l2.17,2.14
	c1.65-0.2,3.27-0.47,4.87-0.81l1.37-2.74l2.51,1.76c1.58-0.46,3.14-0.99,4.66-1.6l0.9-2.93l2.76,1.32c1.48-0.72,2.93-1.5,4.33-2.35
	l0.4-3.03l2.93,0.85c1.35-0.95,2.64-1.97,3.9-3.04l-0.1-3.04l3.02,0.35c1.17-1.16,2.29-2.38,3.35-3.64l-0.6-2.99l3.05-0.15
	c0.96-1.33,1.86-2.71,2.7-4.13l-1.09-2.85l2.99-0.65c0.73-1.47,1.39-2.97,1.98-4.51l-1.55-2.64l2.84-1.14
	c0.47-1.56,0.88-3.16,1.21-4.78l-1.96-2.35l2.61-1.59c0.21-1.62,0.34-3.26,0.4-4.92L111.62,60l2.31-1.99
	c-0.06-1.66-0.19-3.3-0.4-4.92l-2.61-1.59l1.96-2.35c-0.33-1.62-0.74-3.21-1.21-4.78l-2.84-1.14l1.55-2.64
	c-0.59-1.54-1.26-3.04-1.98-4.51l-2.99-0.65l1.09-2.85c-0.84-1.42-1.74-2.8-2.7-4.13l-3.05-0.15l0.6-2.99
	c-1.06-1.26-2.18-2.48-3.35-3.64l-3.02,0.35l0.1-3.04c-1.25-1.07-2.55-2.09-3.9-3.04l-2.93,0.85l-0.4-3.03
	c-1.4-0.85-2.85-1.63-4.33-2.35l-2.76,1.32l-0.9-2.93c-1.52-0.6-3.07-1.13-4.66-1.6l-2.51,1.76l-1.37-2.74
	c-1.6-0.34-3.22-0.61-4.87-0.81l-2.17,2.14l-1.79-2.46C61.65,6.05,60.83,6.03,60,6.03c-0.83,0-1.65,0.02-2.47,0.06l-1.79,2.46
	l-2.17-2.14c-1.65,0.2-3.27,0.47-4.87,0.81l-1.37,2.74L44.82,8.2c-1.58,0.46-3.14,0.99-4.66,1.6l-0.9,2.93L36.5,11.4
	c-1.48,0.72-2.93,1.5-4.33,2.35l-0.4,3.03l-2.93-0.85c-1.35,0.95-2.64,1.97-3.9,3.04l0.1,3.04l-3.02-0.35
	c-1.17,1.16-2.29,2.38-3.35,3.64l0.6,2.99l-3.05,0.15c-0.96,1.33-1.86,2.71-2.7,4.13l1.09,2.85l-2.99,0.65
	c-0.73,1.47-1.39,2.97-1.98,4.51l1.55,2.64l-2.84,1.14c-0.47,1.56-0.88,3.16-1.21,4.78l1.96,2.35l-2.61,1.59
	c-0.21,1.62-0.34,3.26-0.4,4.92L8.38,60l-2.31,1.99c0.06,1.66,0.19,3.3,0.4,4.92l2.61,1.59l-1.96,2.35
	C7.45,72.47,7.86,74.06,8.33,75.62z"
  />
  <circle 
    fill={"none"}
    stroke={"currentColor"}
    strokeWidth={4}
    strokeLinecap={"round"}
    strokeLinejoin={"round"}
    strokeMiterlimit={10} cx="60" cy="59.97" r="6.28"
  />
</svg>
)

export default Wheel;
