/**
 * CkCheckbox Component
 *
 * `CkCheckbox` is a component acting as a wrapper around the Ant Design `Checkbox` and `Form.Item` components.
 * This custom component also provides its own CSS classes, and adds the ability to define property to AntD Checkbox.
 *
 * @component
 * @example
 * // This is how you can use the CkCheckbox component
 * <CkCheckbox checkboxProps={{disabled: true}}>I am a checkbox</CkCheckbox>
 *
 * @extends {FormItemProps}
 *
 * @property {CheckboxProps} checkboxProps - Optional: Additional properties for the Ant Design Checkbox component
 * @property {string} className - Optional: Additional CSS classes to apply
 * @property {ReactNode} children - Optional: Content to display inside the checkbox label
 * @property {boolean} noMargin - Optional: If true, removes the margin
 */
import React, { FC, ReactNode } from "react";
import { Checkbox, Form, FormItemProps, CheckboxProps } from "antd";
import "./styles.css";

export interface CkCheckboxProps extends FormItemProps {
  /**
   * Optional: Additional properties for the Ant Design Checkbox component
   */
  checkboxProps?: CheckboxProps;
  /**
   * Optional: Additional CSS classes to apply to form item
   */
  className?: string;
  /**
   * Optional: Content to display inside the checkbox label
   */
  children?: ReactNode;
  /**
   * Optional: If true, removes the margin of Form.Item
   */
  noMargin?: boolean;
}

/**
 * Extends AntD Form.Item props
 * See full doc on https://4x.ant.design/components/form/#Form.Item
 * checkboxProps extends AntD Checkbox, see full docs on https://4x.ant.design/components/checkbox/
 */
const CkCheckbox: FC<CkCheckboxProps> = ({
  className,
  checkboxProps,
  children,
  noMargin = false,
  ...props
}) => (
  <Form.Item
    className={[
      "ck-checkbox",
      ...(checkboxProps && checkboxProps?.children
        ? ["wrapper-with-label"]
        : []),
      ...(noMargin
        ? ["no-margin"]
        : []),
      ...(className ? [className] : []),
    ].join(" ")}
    {...props}
  >
    <Checkbox {...(checkboxProps || {})}>{children && children}</Checkbox>
  </Form.Item>
);

export default CkCheckbox;
