import React from "react";

const Tag = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4288_26308)">
      <path
        d="M19.5091 8.80983L18.8484 1.83215C18.8149 1.47055 18.527 1.18483 18.1654 1.14912L11.1877 0.488403H11.1788C11.1073 0.488403 11.0515 0.510725 11.0091 0.553136L0.556 11.0063C0.535308 11.0269 0.518891 11.0514 0.507689 11.0784C0.496488 11.1054 0.490723 11.1344 0.490723 11.1636C0.490723 11.1929 0.496488 11.2218 0.507689 11.2488C0.518891 11.2758 0.535308 11.3003 0.556 11.321L8.67654 19.4415C8.71895 19.4839 8.77475 19.5063 8.83502 19.5063C8.89529 19.5063 8.95109 19.4839 8.9935 19.4415L19.4466 8.9884C19.4913 8.94153 19.5136 8.8768 19.5091 8.80983ZM8.83279 17.2005L2.79707 11.1647L11.7189 2.24287L17.2323 2.76519L17.7547 8.27858L8.83279 17.2005ZM13.7502 4.28305C12.6676 4.28305 11.7859 5.16474 11.7859 6.24733C11.7859 7.32992 12.6676 8.21162 13.7502 8.21162C14.8328 8.21162 15.7145 7.32992 15.7145 6.24733C15.7145 5.16474 14.8328 4.28305 13.7502 4.28305ZM13.7502 6.96162C13.3551 6.96162 13.0359 6.64242 13.0359 6.24733C13.0359 5.85224 13.3551 5.53305 13.7502 5.53305C14.1453 5.53305 14.4645 5.85224 14.4645 6.24733C14.4645 6.64242 14.1453 6.96162 13.7502 6.96162Z"
        fill="#2369BD"/>
    </g>
    <defs>
      <clipPath id="clip0_4288_26308">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export default Tag;
