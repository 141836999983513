import React from "react";

const WorkshopWorkerCar = () => (
  <svg
    width="115"
    height="72"
    viewBox="0 0 115 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1747_9230)">
      <path
        d="M42.5 52.6H26.6C25.5 52.6 24.6 51.7 24.6 50.6V42.5H26.6V50.6H42.5L42.4 42.5H44.4L44.5 50.6C44.5 51.1 44.3 51.7 43.9 52.1C43.6 52.4 43 52.6 42.5 52.6Z"
        fill="currentColor"
      />
      <path
        d="M48.4999 13.3996V10.2996C48.4999 7.59961 46.9999 5.19961 44.7999 4.09961C42.4999 2.99961 38.9999 1.59961 34.4999 1.59961C30.1999 1.59961 26.5999 2.89961 24.4999 4.09961C22.2999 5.19961 20.8999 7.69961 20.8999 10.2996V13.3996L19.8999 19.9996V20.1996C19.8999 20.7996 20.1999 21.3996 20.6999 21.7996C21.1999 22.1996 21.7999 22.2996 22.3999 22.1996C24.5999 21.6996 30.0999 20.3996 34.7999 20.3996C39.3999 20.3996 44.9999 21.5996 47.1999 22.1996C47.3999 22.1996 47.4999 22.2996 47.6999 22.2996C48.0999 22.2996 48.5999 22.1996 48.8999 21.8996C49.3999 21.4996 49.6999 20.9996 49.6999 20.2996L48.4999 13.3996ZM25.3999 5.89961C27.3999 4.89961 30.5999 3.69961 34.4999 3.69961C38.5999 3.69961 41.8999 4.89961 43.8999 5.89961C45.4999 6.69961 46.4999 8.39961 46.4999 10.2996V13.2996V13.4996V13.6996V13.7996C43.7999 12.9996 38.8999 11.7996 34.6999 11.7996C30.3999 11.7996 25.4999 13.0996 22.7999 13.7996C22.7999 13.7996 22.7999 13.7996 22.7999 13.6996V13.4996V13.2996V10.2996C22.7999 8.39961 23.7999 6.69961 25.3999 5.89961ZM34.6999 18.2996C29.7999 18.2996 24.0999 19.5996 21.7999 20.0996L22.4999 15.6996C22.7999 15.6996 22.9999 15.6996 23.2999 15.6996C25.2999 15.0996 30.3999 13.6996 34.6999 13.6996C38.8999 13.6996 44.0999 15.0996 46.0999 15.6996C46.2999 15.6996 46.3999 15.7996 46.5999 15.7996C46.6999 15.7996 46.7999 15.7996 46.8999 15.7996L47.5999 20.1996C45.1999 19.5996 39.3999 18.2996 34.6999 18.2996Z"
        fill="currentColor"
      />
      <path
        d="M34.6999 10.8992C36.3999 10.8992 37.7999 9.49922 37.7999 7.79922C37.7999 6.09922 36.3999 4.69922 34.6999 4.69922C32.9999 4.69922 31.5999 6.09922 31.5999 7.79922C31.5999 9.49922 32.8999 10.8992 34.6999 10.8992ZM34.6999 6.69922C35.2999 6.69922 35.7999 7.19922 35.7999 7.79922C35.7999 8.39922 35.2999 8.89922 34.6999 8.89922C34.0999 8.89922 33.5999 8.39922 33.5999 7.79922C33.5999 7.19922 34.0999 6.69922 34.6999 6.69922Z"
        fill="currentColor"
      />
      <path
        d="M58 45.6998C57.1 44.4998 55.7 43.6998 54.3 43.2998L39.6 40.2998V37.2998C41.2 36.6998 42.6 35.6998 43.8 34.4998C46.2 32.0998 47.5 28.8998 47.5 25.5998V20.2998H45.4V25.6998C45.4 27.0998 45.1 28.3998 44.6 29.6998C44.1 30.9998 43.3 32.0998 42.3 33.0998C41.3 34.0998 40.2 34.8998 38.9 35.3998C38.8 35.3998 38.8 35.3998 38.7 35.4998C38.5 35.4998 38.4 35.4998 38.2 35.4998C37.1 35.8998 36 36.0998 34.9 36.0998H34.4C33.3 36.0998 32.1 35.8998 31.1 35.4998C30.9 35.4998 30.8 35.3998 30.6 35.4998C29.3 34.9998 28.1 34.1998 27 33.1998C26 32.1998 25.2 31.0998 24.7 29.7998C24.2 28.4998 23.9 27.1998 23.9 25.7998V20.3998H21.8V25.5998C21.8 28.8998 23.1 32.1998 25.5 34.4998C26.7 35.6998 28.1 36.5998 29.7 37.2998V40.2998L15.1 43.2998C13.6 43.5998 12.3 44.4998 11.4 45.6998C10.5 46.8998 10 48.2998 10 49.8998V58.8998H12.1V49.8998C12.1 48.8998 12.4 47.8998 13.1 47.0998C13.7 46.2998 14.6 45.6998 15.6 45.4998L19.6 44.6998C19.5 44.9998 19.5 45.3998 19.5 45.7998V62.2998H21.5V45.5998C21.5 45.0998 21.4 44.5998 21.3 44.1998L29.9 42.3998C30.2 43.3998 30.8 44.1998 31.6 44.7998C32.4 45.3998 33.4 45.6998 34.4 45.6998H35.1C36.1 45.6998 37.1 45.3998 37.9 44.7998C38.7 44.1998 39.3 43.2998 39.6 42.3998L47.8 44.0998C47.5 44.5998 47.5 45.0998 47.5 45.5998V62.0998H49.6V45.5998C49.6 45.1998 49.6 44.7998 49.5 44.4998L53.8 45.3998C54.8 45.5998 55.7 46.1998 56.3 46.9998C56.9 47.7998 57.3 48.7998 57.3 49.8998V58.7998H59.4V49.7998C59.4 48.2998 58.9 46.8998 58 45.6998ZM37.5 40.8998C37.5 41.6998 37.2 42.3998 36.7 42.8998C36.2 43.3998 35.4 43.6998 34.7 43.6998C33.9 43.6998 33.2 43.3998 32.7 42.8998C32.2 42.3998 31.9 41.5998 31.9 40.8998V37.9998C32.7 38.1998 33.6 38.2998 34.4 38.2998H34.5H35C35.9 38.2998 36.8 38.1998 37.6 37.9998V40.8998H37.5Z"
        fill="currentColor"
      />
    </g>
    <g clipPath="url(#clip1_1747_9230)">
      <path
        d="M68.2143 47.6998H66.0158C65.2973 47.6998 64.6079 47.4162 64.0974 46.9106C63.587 46.4049 63.2968 45.7182 63.29 44.9998V42.0298C63.29 41.7741 63.3916 41.5288 63.5725 41.348C63.7533 41.1671 63.9986 41.0655 64.2543 41.0655H69.9115C70.0386 41.0638 70.1647 41.0875 70.2825 41.1354C70.4003 41.1832 70.5073 41.2542 70.5972 41.3441C70.6871 41.434 70.758 41.541 70.8059 41.6588C70.8537 41.7765 70.8775 41.9027 70.8758 42.0298V44.9998C70.8693 45.7072 70.588 46.3845 70.0913 46.8883C69.5947 47.3921 68.9216 47.6831 68.2143 47.6998ZM65.2829 42.9941V44.9998C65.2829 45.2112 65.3669 45.414 65.5164 45.5635C65.6659 45.713 65.8686 45.7969 66.08 45.7969H68.2143C68.319 45.7969 68.4227 45.7763 68.5194 45.7363C68.6161 45.6962 68.704 45.6375 68.778 45.5635C68.852 45.4894 68.9107 45.4016 68.9508 45.3049C68.9908 45.2081 69.0115 45.1045 69.0115 44.9998V42.9941H65.2829Z"
        fill="currentColor"
      />
      <path
        d="M91.1 47.6998H88.9786C88.6196 47.7049 88.2631 47.639 87.9296 47.5059C87.5961 47.3728 87.2922 47.1752 87.0354 46.9243C86.7785 46.6734 86.5737 46.3742 86.4328 46.044C86.2919 45.7137 86.2177 45.3588 86.2143 44.9998V42.0298C86.2126 41.9027 86.2364 41.7765 86.2842 41.6588C86.332 41.541 86.403 41.434 86.4929 41.3441C86.5828 41.2542 86.6898 41.1832 86.8076 41.1354C86.9253 41.0875 87.0515 41.0638 87.1786 41.0655H92.8229C92.95 41.0638 93.0762 41.0875 93.1939 41.1354C93.3117 41.1832 93.4187 41.2542 93.5086 41.3441C93.5985 41.434 93.6695 41.541 93.7173 41.6588C93.7651 41.7765 93.7889 41.9027 93.7872 42.0298V44.9998C93.7805 45.7116 93.4957 46.3926 92.9935 46.8971C92.4914 47.4017 91.8118 47.6898 91.1 47.6998ZM88.1815 42.9941V44.9998C88.1815 45.2112 88.2654 45.414 88.4149 45.5635C88.5644 45.713 88.7672 45.7969 88.9786 45.7969H91.1C91.3115 45.7969 91.5142 45.713 91.6637 45.5635C91.8132 45.414 91.8972 45.2112 91.8972 44.9998V42.9941H88.1815Z"
        fill="currentColor"
      />
      <path
        d="M70.2071 40.1659H65.8486C65.5928 40.1659 65.3475 40.0643 65.1667 39.8834C64.9859 39.7026 64.8843 39.4573 64.8843 39.2016C64.8843 38.9458 64.9859 38.7006 65.1667 38.5197C65.3475 38.3389 65.5928 38.2373 65.8486 38.2373H70.2071C70.4629 38.2373 70.7081 38.3389 70.889 38.5197C71.0698 38.7006 71.1714 38.9458 71.1714 39.2016C71.1714 39.4573 71.0698 39.7026 70.889 39.8834C70.7081 40.0643 70.4629 40.1659 70.2071 40.1659Z"
        fill="currentColor"
      />
      <path
        d="M91.28 40.1659H86.9086C86.6529 40.1659 86.4076 40.0643 86.2268 39.8834C86.0459 39.7026 85.9443 39.4573 85.9443 39.2016C85.9443 38.9458 86.0459 38.7006 86.2268 38.5197C86.4076 38.3389 86.6529 38.2373 86.9086 38.2373H91.28C91.5358 38.2373 91.7811 38.3389 91.9619 38.5197C92.1427 38.7006 92.2443 38.9458 92.2443 39.2016C92.2443 39.4573 92.1427 39.7026 91.9619 39.8834C91.7811 40.0643 91.5358 40.1659 91.28 40.1659Z"
        fill="currentColor"
      />
      <path
        d="M83.4372 35.7938H73.6786C73.4229 35.7938 73.1776 35.6922 72.9968 35.5114C72.8159 35.3305 72.7144 35.0853 72.7144 34.8295C72.7144 34.5738 72.8159 34.3285 72.9968 34.1477C73.1776 33.9668 73.4229 33.8652 73.6786 33.8652H83.4372C83.693 33.8652 83.9382 33.9668 84.1191 34.1477C84.2999 34.3285 84.4015 34.5738 84.4015 34.8295C84.4015 35.0853 84.2999 35.3305 84.1191 35.5114C83.9382 35.6922 83.693 35.7938 83.4372 35.7938Z"
        fill="currentColor"
      />
      <path
        d="M81.393 39.2147H76.5073C76.2515 39.2147 76.0062 39.1131 75.8254 38.9323C75.6446 38.7514 75.543 38.5062 75.543 38.2504C75.543 37.9947 75.6446 37.7494 75.8254 37.5686C76.0062 37.3877 76.2515 37.2861 76.5073 37.2861H81.393C81.6487 37.2861 81.894 37.3877 82.0748 37.5686C82.2557 37.7494 82.3573 37.9947 82.3573 38.2504C82.3573 38.5062 82.2557 38.7514 82.0748 38.9323C81.894 39.1131 81.6487 39.2147 81.393 39.2147Z"
        fill="currentColor"
      />
      <path
        d="M92.8614 31.4228H92.72C88.2853 30.8057 83.8131 30.4964 79.3357 30.4971C74.3297 30.5326 69.3294 30.8416 64.3571 31.4228C64.1017 31.4519 63.845 31.3791 63.6429 31.2203C63.4407 31.0614 63.3093 30.8293 63.2771 30.5742C63.261 30.4491 63.2698 30.3221 63.3032 30.2005C63.3366 30.0788 63.3939 29.9651 63.4717 29.8658C63.5494 29.7665 63.6462 29.6836 63.7562 29.622C63.8663 29.5605 63.9875 29.5214 64.1128 29.5071C69.1576 28.9154 74.2308 28.5978 79.31 28.5557C83.8966 28.5749 88.4763 28.9143 93.0157 29.5714C93.2544 29.6107 93.4695 29.7384 93.6185 29.9289C93.7675 30.1195 93.8394 30.3591 93.8199 30.6002C93.8005 30.8413 93.6912 31.0663 93.5137 31.2306C93.3361 31.3949 93.1033 31.4864 92.8614 31.4871V31.4228Z"
        fill="currentColor"
      />
      <path
        d="M68.0343 37.2857C67.4692 37.2882 66.9161 37.123 66.445 36.8109C65.9739 36.4988 65.6061 36.054 65.3881 35.5326C65.1701 35.0113 65.1117 34.437 65.2204 33.8825C65.3291 33.328 65.5999 32.8182 65.9986 32.4177C66.3972 32.0172 66.9058 31.7441 67.4598 31.6329C68.0138 31.5218 68.5884 31.5775 69.1107 31.7932C69.633 32.0088 70.0795 32.3747 70.3937 32.8443C70.708 33.314 70.8757 33.8663 70.8757 34.4314C70.8757 35.1862 70.5767 35.9102 70.0442 36.4452C69.5117 36.9801 68.789 37.2823 68.0343 37.2857ZM68.0343 33.5057C67.7887 33.5057 67.5533 33.6032 67.3797 33.7768C67.2061 33.9504 67.1085 34.1859 67.1085 34.4314C67.1085 34.6769 67.2061 34.9124 67.3797 35.086C67.5533 35.2596 67.7887 35.3571 68.0343 35.3571C68.2798 35.3571 68.5152 35.2596 68.6888 35.086C68.8624 34.9124 68.96 34.6769 68.96 34.4314C68.96 34.1859 68.8624 33.9504 68.6888 33.7768C68.5152 33.6032 68.2798 33.5057 68.0343 33.5057Z"
        fill="currentColor"
      />
      <path
        d="M89.0943 37.2857C88.5298 37.2857 87.9779 37.1183 87.5085 36.8047C87.0391 36.4911 86.6733 36.0453 86.4573 35.5237C86.2412 35.0022 86.1847 34.4283 86.2948 33.8746C86.405 33.3209 86.6768 32.8123 87.076 32.4131C87.4752 32.014 87.9838 31.7421 88.5374 31.632C89.0911 31.5219 89.665 31.5784 90.1866 31.7944C90.7081 32.0105 91.1539 32.3763 91.4675 32.8457C91.7812 33.3151 91.9486 33.8669 91.9486 34.4314C91.9486 35.1884 91.6478 35.9144 91.1126 36.4497C90.5773 36.985 89.8513 37.2857 89.0943 37.2857ZM89.0943 33.5057C88.9112 33.5057 88.7322 33.56 88.58 33.6617C88.4277 33.7634 88.3091 33.908 88.239 34.0772C88.169 34.2463 88.1506 34.4325 88.1864 34.612C88.2221 34.7916 88.3102 34.9566 88.4397 35.086C88.5692 35.2155 88.7341 35.3036 88.9137 35.3394C89.0932 35.3751 89.2794 35.3567 89.4485 35.2867C89.6177 35.2166 89.7623 35.098 89.864 34.9457C89.9657 34.7935 90.02 34.6145 90.02 34.4314C90.0068 34.1949 89.9035 33.9725 89.7313 33.8099C89.5591 33.6472 89.3311 33.5568 89.0943 33.5571V33.5057Z"
        fill="currentColor"
      />
      <path
        d="M64.4985 30.9475C64.3711 30.9524 64.2441 30.9305 64.1257 30.8832L61.9142 29.9704C61.6619 29.907 61.4249 29.7935 61.2173 29.6367C61.0096 29.4798 60.8357 29.2829 60.7057 29.0575C60.5584 28.8199 60.4639 28.5535 60.4284 28.2763C60.3929 27.999 60.4173 27.7174 60.5 27.4504L60.6928 26.8718C60.8739 26.334 61.2197 25.8669 61.6813 25.5369C62.1429 25.2068 62.6968 25.0306 63.2642 25.0332H66.4785C66.6422 25.0331 66.803 25.0757 66.9451 25.1569C67.0872 25.2381 67.2056 25.355 67.2885 25.4961C67.3673 25.64 67.4086 25.8013 67.4086 25.9654C67.4086 26.1294 67.3673 26.2908 67.2885 26.4347L65.2957 30.4204C65.2201 30.5699 65.1068 30.6972 64.967 30.7896C64.8272 30.8821 64.6658 30.9365 64.4985 30.9475ZM63.2771 27.0004C63.1201 27.0007 62.9672 27.0507 62.8402 27.143C62.7133 27.2353 62.6187 27.3654 62.57 27.5147L62.3642 28.1061C62.436 28.1282 62.505 28.1584 62.57 28.1961L64.0357 28.8004L64.9228 27.0004H63.2771Z"
        fill="currentColor"
      />
      <path
        d="M92.6429 30.9467C92.4643 30.9485 92.2888 30.9001 92.1364 30.8069C91.9841 30.7136 91.8611 30.5794 91.7814 30.4196L89.7886 26.4338C89.7062 26.2912 89.6628 26.1293 89.6628 25.9645C89.6628 25.7998 89.7062 25.6379 89.7886 25.4953C89.8755 25.3549 89.9965 25.2388 90.1404 25.1579C90.2843 25.0769 90.4464 25.0338 90.6114 25.0324H93.8257C94.3941 25.0258 94.9498 25.2005 95.4122 25.5311C95.8746 25.8617 96.2196 26.331 96.3972 26.871L96.6029 27.4496C96.6955 27.7082 96.7328 27.9834 96.7126 28.2574C96.6923 28.5314 96.6149 28.7981 96.4853 29.0404C96.3557 29.2826 96.1768 29.495 95.9601 29.6639C95.7434 29.8328 95.4937 29.9544 95.2272 30.021L93.0029 30.8567C92.8899 30.91 92.7676 30.9406 92.6429 30.9467ZM92.1929 26.9996L93.0929 28.7995L94.5586 28.1953H94.7257L94.5586 27.5653C94.5099 27.416 94.4153 27.286 94.2883 27.1936C94.1613 27.1013 94.0084 27.0513 93.8514 27.051L92.1929 26.9996Z"
        fill="currentColor"
      />
      <path
        d="M102.633 14.8758H54.4957C54.2399 14.8758 53.9946 14.7742 53.8138 14.5934C53.633 14.4126 53.5314 14.1673 53.5314 13.9116C53.5314 13.6558 53.633 13.4105 53.8138 13.2297C53.9946 13.0489 54.2399 12.9473 54.4957 12.9473H102.633C102.889 12.9473 103.134 13.0489 103.315 13.2297C103.495 13.4105 103.597 13.6558 103.597 13.9116C103.597 14.1673 103.495 14.4126 103.315 14.5934C103.134 14.7742 102.889 14.8758 102.633 14.8758Z"
        fill="currentColor"
      />
      <path
        d="M65.72 42.9944C64.5765 42.991 63.481 42.5343 62.6736 41.7246C61.8662 40.9148 61.4128 39.8179 61.4129 38.6744V33.5315C61.4118 33.0349 61.5089 32.5429 61.6987 32.084C61.8884 31.625 62.1671 31.2081 62.5186 30.8572L63.47 29.9187L66.5814 23.6701C67.1161 22.591 67.9432 21.6838 68.9684 21.0518C69.9936 20.4199 71.1757 20.0886 72.38 20.0958H84.6971C85.8914 20.1559 87.0515 20.5145 88.0713 21.1389C89.0911 21.7634 89.9379 22.6337 90.5343 23.6701L93.6586 29.9187L94.5971 30.8572C94.9519 31.209 95.2334 31.6276 95.4254 32.0888C95.6174 32.5501 95.7161 33.0448 95.7157 33.5444V38.6872C95.713 39.2568 95.5981 39.8202 95.3775 40.3452C95.1569 40.8703 94.8349 41.3467 94.43 41.7472C94.0327 42.1529 93.5587 42.4755 93.0356 42.6962C92.5125 42.9169 91.9506 43.0314 91.3829 43.033H65.6686L65.72 42.9944ZM72.38 21.9987C71.5349 21.997 70.7059 22.2306 69.9861 22.6734C69.2662 23.1162 68.6839 23.7507 68.3043 24.5058L65.1157 30.9344C65.0719 31.0266 65.0108 31.1095 64.9357 31.1787L63.8814 32.233C63.5441 32.577 63.351 33.0369 63.3414 33.5187V38.6615C63.3414 39.2936 63.5916 39.8999 64.0373 40.348C64.483 40.7961 65.088 41.0496 65.72 41.053C65.72 41.053 83.72 41.053 91.4343 41.053C91.7482 41.0547 92.0593 40.9939 92.3495 40.8742C92.6397 40.7545 92.9032 40.5782 93.1246 40.3556C93.346 40.133 93.5208 39.8686 93.639 39.5777C93.7572 39.2869 93.8163 38.9754 93.8129 38.6615V33.5187C93.8039 33.0343 93.6054 32.5727 93.26 32.233L92.2057 31.1787C92.1254 31.0795 92.0564 30.9717 92 30.8572L88.8114 24.4287C88.3825 23.7066 87.7832 23.1005 87.066 22.6635C86.3488 22.2265 85.5355 21.9717 84.6971 21.9215L72.38 21.9987Z"
        fill="currentColor"
      />
      <path
        d="M102.633 51.8657C102.506 51.8674 102.38 51.8436 102.262 51.7958C102.144 51.7479 102.037 51.677 101.947 51.5871C101.857 51.4972 101.786 51.3902 101.738 51.2724C101.691 51.1547 101.667 51.0285 101.669 50.9014V9.23139C101.669 8.94155 101.553 8.66357 101.348 8.45862C101.143 8.25367 100.865 8.13853 100.576 8.13853H56.5528C56.263 8.13853 55.985 8.25367 55.78 8.45862C55.5751 8.66357 55.4599 8.94155 55.4599 9.23139V43.9063C55.4599 44.1621 55.3583 44.4074 55.1775 44.5882C54.9967 44.769 54.7514 44.8706 54.4957 44.8706C54.2399 44.8706 53.9946 44.769 53.8138 44.5882C53.633 44.4074 53.5314 44.1621 53.5314 43.9063V9.23139C53.5314 8.43006 53.8497 7.66154 54.4163 7.09492C54.983 6.52829 55.7515 6.20996 56.5528 6.20996H100.576C101.376 6.21334 102.142 6.53276 102.708 7.09866C103.274 7.66455 103.594 8.4311 103.597 9.23139V50.9014C103.597 51.1571 103.495 51.4024 103.315 51.5832C103.134 51.7641 102.889 51.8657 102.633 51.8657Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1747_9230">
        <rect width="64" height="64" fill="white" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip1_1747_9230">
        <rect width="72" height="72" fill="white" transform="translate(42.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default WorkshopWorkerCar;
