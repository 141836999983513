import React from "react";

const WhatsappOutline = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25781 14.5889C1.13998 12.703 0.748976 10.4738 1.15821 8.32003C1.56743 6.16622 2.74875 4.23585 4.48039 2.8913C6.21202 1.54676 8.37488 0.880508 10.5629 1.01763C12.751 1.15476 14.8137 2.08583 16.364 3.63604C17.9142 5.18626 18.8452 7.24902 18.9824 9.43707C19.1195 11.6251 18.4532 13.788 17.1087 15.5196C15.7642 17.2512 13.8338 18.4326 11.68 18.8418C9.52616 19.251 7.29704 18.86 5.41109 17.7422L2.29535 18.6244C2.1677 18.6617 2.03235 18.664 1.9035 18.631C1.77464 18.5981 1.65703 18.5311 1.56298 18.437C1.46894 18.343 1.40192 18.2254 1.36896 18.0965C1.336 17.9676 1.33831 17.8323 1.37564 17.7046L2.25781 14.5889Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2711 15.2449C11.2833 15.2474 10.3046 15.0547 9.39149 14.6778C8.47835 14.3009 7.64867 13.7473 6.95014 13.0487C6.25161 12.3502 5.698 11.5205 5.3211 10.6074C4.9442 9.69423 4.75145 8.71561 4.75393 7.72774C4.75641 7.03245 5.03436 6.36649 5.52689 5.87572C6.01941 5.38495 6.68637 5.10939 7.38166 5.1094C7.49638 5.10848 7.60922 5.1385 7.70831 5.19631C7.8074 5.25411 7.88908 5.33756 7.94475 5.43786L9.04276 7.35235C9.10806 7.46873 9.14157 7.60026 9.13992 7.73369C9.13828 7.86713 9.10153 7.99778 9.03338 8.11252L8.15121 9.58593C8.60307 10.5911 9.40774 11.3958 10.4129 11.8477L11.8863 10.9655C12.0011 10.8973 12.1317 10.8606 12.2652 10.8589C12.3986 10.8573 12.5301 10.8908 12.6465 10.9561L14.561 12.0541C14.6613 12.1098 14.7448 12.1915 14.8026 12.2906C14.8604 12.3896 14.8904 12.5025 14.8895 12.6172C14.887 13.3117 14.6107 13.9773 14.1204 14.4693C13.6302 14.9613 12.9656 15.24 12.2711 15.2449Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WhatsappOutline;
